export type Category = {
	id: number;
	parentId?: number;
	name: string;
	parent?: Category;
	isMeasurable?: boolean;
	isDebitable?: boolean;
	isDeactivated?: boolean;
	requiresInstallation?: boolean;
	categoryType?: CategoryType;
	leadTime?: number;
	projectProcessMasterId?: number;
};

export enum CategoryType {
	MATERIAL_QUERY = 'MATERIAL_QUERY',
	WORK_ORDER_QUERY = 'WORK_ORDER_QUERY',
}

export const categoryTypeLabels: Record<string, string> = {
	[CategoryType.MATERIAL_QUERY]: 'Material Query',
	[CategoryType.WORK_ORDER_QUERY]: 'Work Order Query',
};
