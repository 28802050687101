import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import type Types from 'MyTypes';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {uiPaths} from 'app/constants';
import {getCurrencyString, parseNum, reduceUniqueIds, toTitleCase} from 'app/helpers';
import {FilterView, type FilterDataType} from '../Common/Filter/FilterView';
import {get, setFilterValue} from '../WorkOrder/actions';
import {Button, Col, Empty, Row, Space, Spin, Table, message} from 'antd';
import {FilterFormType, type FilterOptionItem, type UiFilter} from 'app/models/ui-filter';
import {SiteStatus, WorkOrderStatus} from 'app/models';
import {get as getSites} from '../Site/actions';
import {get as getWorkTypes} from '../ProjectProcessMaster/actions';
import {ProjectProcessType} from '../ProjectProcessMaster/project-process-master';
import {CSVLink} from 'react-csv';

export type DataSourceType = {
	key: string;
	siteName: string;
	workOrderName: string;
	contractorName: string;
	workType: string;
	workOrderStatus: string;
	totalAmount: number;
	balanceAmount: number;
	totalDebitAmount: number;
	balanceDebitAmount: number;
	debitPercentage: number;
};

export const ViewWorkOrderRemainingDebitsReport: React.FC = () => {
	const dispatch = useDispatch();
	const {loading, byIds, allIds, filterValue} = useSelector(
		(state: Types.RootState) => state.workOrder,
	);
	const {sites} = useSelector(
		(state: Types.RootState) => state.site,
	);
	const {byIds: workTypeByIds, allIds: workTypeAllIds} = useSelector((state: Types.RootState) => state.projectProcessMaster);

	const siteOptions: FilterOptionItem[] = sites.map(site => ({
		label: site.name,
		value: String(site.id),
	}));

	const workTypeOptions: FilterOptionItem[] = workTypeAllIds.map(id => ({
		label: workTypeByIds[id]?.processName,
		value: String(id),
	}));

	// Add work order status options
	const workOrderStatusOptions: FilterOptionItem[] = [
		{label: 'Published', value: WorkOrderStatus.PUBLISHED},
		{label: 'Completed', value: WorkOrderStatus.COMPLETED},
		{label: 'Terminated', value: WorkOrderStatus.TERMINATED},
	];

	React.useEffect(() => {
		dispatch(
			getSites({
				where: {
					status: {inq: [
						SiteStatus.UNDER_DESIGN,
						SiteStatus.UNDER_CONSTRUCTION,
						SiteStatus.UNDER_WARRANTY,
						SiteStatus.RECENTLY_COMPLETED,
					]},
				},
			}),
		);
		dispatch(getWorkTypes({
			where: {
				projectProcessType: ProjectProcessType.ORDER,
			},
		}));
	}, []);

	const filterData = (f: FilterDataType = {}) => {
		const filter: any = {
			where: {
				status: {inq: [WorkOrderStatus.PUBLISHED, WorkOrderStatus.COMPLETED, WorkOrderStatus.TERMINATED]},
			},
			include: [
				{relation: 'contractorForemen'},
				{relation: 'paymentMilestones'},
				{relation: 'debits'},
			],
		};

		if (f.siteId) {
			filter.where.siteId = parseNum(String(f.siteId));
		}

		if (f.workTypeId) {
			filter.where.projectProcessMasterId = f.workTypeId;
		}

		// Add status filter if provided
		if (f.workOrderStatus) {
			filter.where.status = f.workOrderStatus;
		}

		dispatch(setFilterValue(f));
		dispatch(get(filter));
	};

	const dataSource: DataSourceType[] = allIds.map(id => byIds[id]).map(item => {
		const totalMilestoneAmount = item.paymentMilestones?.length ? item.paymentMilestones.reduce((total, milestone) => total + milestone.amount, 0) : 0;
		const milestoneWorkOrderBillIds = item.paymentMilestones?.length ? item.paymentMilestones
			.reduce<number[]>((ids, {workOrderBillId: id}) => reduceUniqueIds<number>(ids, id), []) : [];

		const totalPaidAgainstMilestones = item.paidBills?.length ? item.paidBills
			.filter(paidBill => milestoneWorkOrderBillIds.includes(paidBill.id))
			.reduce((total, paidBill) => {
				total += paidBill.billAmount;

				return total;
			}, 0) : 0;

		const totalDebitAmount = item.debits?.length ? item.debits
			.reduce((total, debit) => {
				total += debit.amount;

				return total;
			}, 0) : 0;

		const balanceAmount = totalMilestoneAmount - totalPaidAgainstMilestones;
		const balanceDebitAmount = item.debits?.length ? item.debits
			.filter(debit => !debit.workOrderBillId)
			.reduce((total, debit) => {
				total += debit.amount;

				return total;
			}, 0) : 0;

		// Calculate percentage of remaining debits to remaining payment
		const debitPercentage = balanceAmount > 0 ? (balanceDebitAmount / balanceAmount) * 100 : 0;

		// Find the site name based on the siteId
		// const siteName = sites.find(site => site.id === item.siteId)?.name ?? '';

		const data: DataSourceType = {
			key: String(item.id),
			siteName: item.site?.name ?? '',
			workOrderName: item.title,
			contractorName: item.contractor?.name ?? '',
			workType: item.projectProcessMaster?.processName ?? '',
			workOrderStatus: toTitleCase(String(item.status), '_') ?? '',
			totalAmount: totalMilestoneAmount,
			balanceAmount,
			totalDebitAmount,
			balanceDebitAmount,
			debitPercentage,
		};
		return data;
	}).filter(item => item.debitPercentage > 50);

	const getCsvData = (data: DataSourceType[]) => [
		[
			'Site Name',
			'Work Order Name',
			'Contractor Name',
			'Work Type',
			'Work Order Status',
			'Total Work Order Amount',
			'Balance Payment',
			'Advance/Debits',
			'Remaining Advance/Debits',
			'Debit Percentage',
		],
		...data.map(d => [
			d.siteName,
			d.workOrderName,
			d.contractorName,
			d.workType,
			d.workOrderStatus,
			d.totalAmount,
			d.balanceAmount,
			d.totalDebitAmount,
			d.balanceDebitAmount,
			`${d.debitPercentage.toFixed(2)}%`,
		]),
	];

	const csvData: any[] = getCsvData(dataSource);

	const uiFilters: UiFilter[] = [
		{
			filterKey: 'siteId',
			items: siteOptions,
			formType: FilterFormType.SITE_SELECT,
			placeholder: 'Select Site (Optional)',
			label: 'Site',
			defaultValue: filterValue?.siteId,
		},
		{
			filterKey: 'workTypeId',
			items: workTypeOptions,
			formType: FilterFormType.SITE_SELECT,
			placeholder: 'Select Type',
			label: 'Work Type',
			defaultValue: filterValue?.workTypeId,
		},
		{
			filterKey: 'workOrderStatus',
			items: workOrderStatusOptions,
			formType: FilterFormType.SELECT,
			placeholder: 'Select Status (Optional)',
			label: 'Work Order Status',
			defaultValue: filterValue?.workOrderStatus,
		},
	];

	// Calculate total work order amount and balance amount
	const totalWorkOrderAmount = dataSource.reduce((sum, record) => sum + record.totalAmount, 0);
	const totalBalanceAmount = dataSource.reduce((sum, record) => sum + record.balanceAmount, 0);
	const totalWorkOrderDebitAmount = dataSource.reduce((sum, record) => sum + record.totalDebitAmount, 0);
	const totalBalanceDebitAmount = dataSource.reduce((sum, record) => sum + record.balanceDebitAmount, 0);

	const dataWithTotal = [
		...dataSource,
		{
			key: 'total',
			siteName: '',
			workOrderName: 'Total',
			contractorName: '',
			workType: '',
			workOrderStatus: '',
			totalAmount: totalWorkOrderAmount,
			balanceAmount: totalBalanceAmount,
			totalDebitAmount: totalWorkOrderDebitAmount,
			balanceDebitAmount: totalBalanceDebitAmount,
			debitPercentage: 0,
		},
	];

	return (
		<DefaultLayout
			currentPath={uiPaths.workOrderRemainingDebitsReport}
		>
			<Spin size='large' spinning={loading} tip={'Loading...'}>
				<Row>
					<Col span={24}>
						<FilterView uiFilters={uiFilters} onSubmit={(f: FilterDataType) => {
							filterData(f);
						}} />
						<br />
					</Col>
				</Row>

				<Row>
					<Col span={24} className='mb-10' style={{textAlign: 'right'}}>
						<Space>
							<CSVLink
								data={csvData}
								filename={'Balance_payment_report.csv'}
								className='btn btn-primary'
							>
								<Button type='primary'>Download</Button>
							</CSVLink>
						</Space>
					</Col>
				</Row>

				{dataSource.length ? (
					<Row>
						<Col span={24}>
							<Table
								size={'small'}
								dataSource={dataWithTotal}
								pagination={false}
								rowClassName={record => (record.key === 'total' ? 'total-row' : '')}
							>
								<Table.Column
									title='Site Name'
									dataIndex='siteName'
									key='siteName'
									render={(text: string, record: DataSourceType) => record.key === 'total' ? <b>{text}</b> : text}
								/>
								<Table.Column
									title='Work Order Name'
									dataIndex='workOrderName'
									key='workOrderName'
									render={(text: string, record: DataSourceType) => record.key === 'total' ? <b>{text}</b> : text}
								/>
								<Table.Column
									title='Contractor Name'
									dataIndex='contractorName'
									key='contractorName'
								/>
								<Table.Column
									title='Work Type'
									dataIndex='workType'
									key='workType'
								/>
								<Table.Column
									title='Work Order Status'
									dataIndex='workOrderStatus'
									key='workOrderStatus'
								/>
								<Table.Column
									title='Total Work Order Amount'
									dataIndex='totalAmount'
									key='totalAmount'
									render={(totalAmount: number, record: DataSourceType) =>
										record.key === 'total' ? <b>{getCurrencyString(totalAmount, false)}</b> : getCurrencyString(totalAmount, false)
									}
								/>
								<Table.Column
									title='Balance Payment'
									dataIndex='balanceAmount'
									key='balanceAmount'
									render={(balanceAmount: number, record: DataSourceType) =>
										record.key === 'total' ? <b>{getCurrencyString(balanceAmount, false)}</b> : getCurrencyString(balanceAmount, false)
									}
								/>
								<Table.Column
									title='Advance/Debits'
									dataIndex='totalDebitAmount'
									key='totalDebitAmount'
									render={(totalDebitAmount: number, record: DataSourceType) =>
										record.key === 'total' ? <b>{getCurrencyString(totalDebitAmount, false)}</b> : getCurrencyString(totalDebitAmount, false)
									}
								/>
								<Table.Column
									title='Remaining Advance/Debits'
									dataIndex='balanceDebitAmount'
									key='balanceDebitAmount'
									render={(balanceDebitAmount: number, record: DataSourceType) =>
										record.key === 'total' ? <b>{getCurrencyString(balanceDebitAmount, false)}</b> : getCurrencyString(balanceDebitAmount, false)
									}
								/>
								<Table.Column
									title='Debit Recovery %'
									dataIndex='debitPercentage'
									key='debitPercentage'
									render={(percentage: number, record: DataSourceType) =>
										record.key === 'total' ? <b>{percentage.toFixed(2)}%</b> : `${percentage.toFixed(2)}%`
									}
								/>
							</Table>
						</Col>
					</Row>
				) : (
					<Row>
						<Col span={24} style={{textAlign: 'center'}}>
							<Empty />
						</Col>
					</Row>
				)}
			</Spin>
		</DefaultLayout>
	);
};
