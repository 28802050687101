import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {
	Button,
	Col,
	Form,
	Input,
	message,
	Row,
	Select,
	Spin,
	Switch,
	Typography,
} from 'antd';
import {uiPaths} from 'app/constants';
import {getById, create, update, getParents} from './actions';
import {useHistory, useParams} from 'react-router';
import {parseNum} from 'app/helpers';
import type Types from 'MyTypes';
import {CategoryType, categoryTypeLabels, type Category} from './category';
import {type FilterOptionItem} from 'app/models/ui-filter';
import {get as getMasters} from '../ProjectProcessMaster/actions';
import {ProjectProcessType} from '../ProjectProcessMaster/project-process-master';

type MyParams = {
	id?: string;
};

export const ViewCategoryForm: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const params = useParams<MyParams>();

	const {loading, byIds, parents, dataUpdated, errorMessage}
		= useSelector((state: Types.RootState) => state.category);
	const {byIds: processMasterByIds, allIds: processMasterAllIds}
		= useSelector((state: Types.RootState) => state.projectProcessMaster);

	const id = parseNum(params?.id);
	const category = byIds[id]
		? (byIds[id])
		: undefined;

	const [categoryType, setCategoryType] = React.useState<string>(category?.categoryType ?? '');
	const [name, setName] = React.useState(
		category?.name ?? '',
	);
	const [parentId, setParentId] = React.useState(
		category?.parentId ?? 0,
	);
	const [projectProcessMasterId, setProjectProcessMasterId] = React.useState<number>(
		category?.projectProcessMasterId ?? 0,
	);
	const [isMeasurable, setIsMeasurable] = React.useState(
		category?.isMeasurable ?? false,
	);
	const [isDebitable, setIsDebitable] = React.useState(
		category?.isDebitable ?? false,
	);
	const [isDeactivated, setIsDeactivated] = React.useState(
		category?.isDeactivated ?? false,
	);
	const [requiresInstallation, setRequiresInstallation] = React.useState(
		category?.requiresInstallation ?? false,
	);
	const [leadTime, setLeadTime] = React.useState(
		category?.leadTime ?? 0,
	);

	React.useEffect(() => {
		dispatch(getParents());
		dispatch(getMasters({where: {projectProcessType: ProjectProcessType.ORDER}}));
	}, []);

	React.useEffect(() => {
		if (id) {
			dispatch(getById(id));
		}
	}, [id]);

	React.useEffect(() => {
		if (dataUpdated) {
			if (id) {
				void message.success('Updated Successfully.');
				dispatch(getById(id));
			} else {
				history.goBack();
			}
		}
	}, [dataUpdated]);

	React.useEffect(() => {
		if (errorMessage) {
			void message.error(errorMessage);
		}
	}, [errorMessage]);

	const onSubmitClicked = () => {
		if (!name) {
			void message.error('Please enter a name!');
			return;
		}

		if (!categoryType) {
			void message.error('Please select a category!');
			return;
		}

		const data: Omit<Category, 'id'> = {
			categoryType: categoryType as CategoryType,
			parentId,
			name,
			isMeasurable,
			isDebitable,
			isDeactivated,
			requiresInstallation,
			leadTime,
			projectProcessMasterId,
		};

		if (category?.id) {
			dispatch(update(category.id, data));
		} else {
			dispatch(create(data));
		}
	};

	const layout = {
		labelCol: {span: 5},
		wrapperCol: {span: 16},
	};
	const tailLayout = {
		wrapperCol: {offset: 8, span: 16},
	};

	const parentOptions = parents.filter(opt => String(opt.categoryType) === categoryType).map(item => ({value: item.id.toString(), label: item.name}));
	const processMasterOptions = processMasterAllIds
		.map(id => ({
			value: id.toString(),
			label: processMasterByIds[id]?.processName,
		}));

	return (
		<DefaultLayout currentPath={uiPaths.addCategory}>
			<Spin
				size='large'
				spinning={loading}
				tip={'Loading...'}
			>
				<Row className='mb-15'>
					<Col span={24}>
						<Typography.Title level={3} style={{textAlign: 'center'}}>
							Category Form
						</Typography.Title>
						<Button className='mb-15' onClick={() => {
							history.goBack();
						}}>
							Back
						</Button>
						<Form {...layout} name='basic'>

							<Form.Item label='Type*'>
								{category?.id ? (
									<b>{categoryTypeLabels[categoryType]}</b>
								) : (
									<Select
										showSearch={true}
										style={{width: '100%'}}
										placeholder='Select a Type'
										defaultValue={categoryTypeLabels[categoryType]}
										onChange={value => {
											setCategoryType(value as CategoryType);
										}}
									>
										{Object.keys(categoryTypeLabels).map((type: string, ix: number) => (
											<Select.Option key={ix} value={type}>
												{categoryTypeLabels[type]}
											</Select.Option>
										))}
									</Select>
								)}
							</Form.Item>
							<Form.Item label='Parent Tab (If Any)' name='parentId'>
								<Select
									showSearch={true}
									optionFilterProp='children'
									placeholder='Select a Parent Tab'
									defaultValue={
										parentId
											? parentOptions.find(
												opt => parseNum(opt.value) === parentId,
											)?.label
											: ''
									}
									onChange={value => {
										setParentId(parseNum(value));
									}}
								>
									{parentOptions.map((item: FilterOptionItem, ix: number) => (
										<Select.Option key={ix} value={item.value}>
											{item.label}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
							<Form.Item
								label='Name'
								rules={[{required: true, message: 'Please Enter Name'}]}
							>
								<Input
									defaultValue={name}
									value={name}
									onChange={(e: any) => {
										setName(String(e.target.value));
									}}
								/>
							</Form.Item>

							{categoryType === String(CategoryType.MATERIAL_QUERY) ? (
								<>
									<Form.Item
										label={'Is this Measurable'}
										help={'Making this category measurable will ask user to provide dimensions for material query.'}
									>
										<Switch
											checked={isMeasurable}
											onChange={checked => {
												setIsMeasurable(checked);
											}}
										/>
									</Form.Item>
									<Form.Item
										label={'Is this Debitable'}
										help={'Making this category debitable will make the material query auto debitable.'}
									>
										<Switch
											checked={isDebitable}
											onChange={checked => {
												setIsDebitable(checked);
											}}
										/>
									</Form.Item>
									<Form.Item
										label={'Requires Installation'}
										help={'Indicates if this category requires installation.'}
									>
										<Switch
											checked={requiresInstallation}
											onChange={checked => {
												setRequiresInstallation(checked);
											}}
										/>
									</Form.Item>
									<Form.Item label='Project Process Master' name='projectProcessMasterId'>
										<Select
											showSearch={true}
											optionFilterProp='children'
											placeholder='Select a Project Process Master'
											defaultValue={
												projectProcessMasterId
													? processMasterOptions.find(opt => parseNum(opt.value) === projectProcessMasterId)?.label
													: ''
											}
											onChange={value => {
												setProjectProcessMasterId(parseNum(value));
											}}
										>
											{processMasterOptions.map((item, ix) => (
												<Select.Option key={ix} value={item.value}>
													{item.label}
												</Select.Option>
											))}
										</Select>
									</Form.Item>

								</>
							) : ([])}

							<Form.Item label='Lead Time'>
								<Input
									type='number'
									defaultValue={leadTime}
									value={leadTime}
									onChange={(e: any) => {
										setLeadTime(parseNum(String(e.target.value)));
									}}
								/>
							</Form.Item>

							<Form.Item
								label={'Deactivate'}
								help={'Making this category deactivate will hide this category from the option.'}
							>
								<Switch
									checked={isDeactivated}
									onChange={checked => {
										setIsDeactivated(checked);
									}}
								/>
							</Form.Item>

							<Form.Item {...tailLayout}>
								<Button type='primary' onClick={onSubmitClicked}>
									Submit
								</Button>
							</Form.Item>
						</Form>
					</Col>
				</Row>
			</Spin>
		</DefaultLayout>
	);
};
