import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {
	Avatar,
	Button,
	Card,
	Col,
	Descriptions,
	Empty,
	Form,
	Input,
	message,
	Modal,
	Row,
	Select,
	Space,
	Spin,
	Badge,
	Radio,
} from 'antd';
import {PaperClipOutlined, UserOutlined} from '@ant-design/icons';
import {
	approve,
	cancel,
	getById,
	update,
	escalate,
	cancelChanges,
	approveChanges,
	receive,
} from './actions';
import {
	checkFileErr,
	equalNum,
	getHrefLink,
	getPermissionSites,
	getS3Url,
	parseNum,
	toTitleCase,
} from 'app/helpers';
import moment from 'moment';
import {UserRole} from 'app/models/enums/user-role.enum';
import {useHistory, useParams} from 'react-router';
import {materialQueryEscalateAfterDays, uiPaths} from 'app/constants';
import {
	CommentParentType,
	MaterialQueryStatus,
	materialQueryStatusLabels,
	ModuleName,
	ModulePermission,
	WorkOrderStatus,
	type Attachment,
	AttachmentParentType,
	type Site,
} from 'app/models';
import {
	get as getComments,
	save as saveComment,
} from 'app/components/Common/comment-actions';
import {type FilterOptionItem} from 'app/models/ui-filter';
import {CommentBlock} from 'app/components/Common/CommentBlock';
import {FileUpload} from 'app/components/Common/FileUpload';
import {type UploadFile} from 'app/models/upload-file';
import type Types from 'MyTypes';
import {CommentComponent} from '../Common/CommentComponent';
import {get as getWorkOrders} from '../WorkOrder/actions';
import PurchaseOrderForm from './PurchaseOrderForm';
import {type MaterialPurchaseOrder} from '../MaterialPurchaseOrder/material-purchase-order';
import {get as getMaterialMasters} from '../MaterialMaster/actions';
import {getVendors} from 'app/components/Common/summary-actions';
import PurchaseOrderView from './PurchaseOrderView';
import {SafeSubmitButton} from '../Common/SafeSubmitButton';

const modelTypeOptions: Record<string, string> = {
	debit: 'Debit to Contractor',
	cancel: 'Cancel Query',
	order: 'Mark Ordered',
	receive: 'Receive Order',
	approve: 'Approve Query',
	locally: 'Buy Locally',
	approveChanges: 'Approve Changes',
	cancelChanges: 'Cancel Changes',
	requestInstallation: 'Request Installation',
	orderInstallation: 'Order Installation',
	completeInstallation: 'Complete Installation',
};

type ParamType = {
	queryId?: string;
};

// eslint-disable-next-line complexity
export const ViewMaterialQueryDetail: React.FC = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const params = useParams<ParamType>();
	const queryId = parseNum(params?.queryId ?? '0');

	const {byIds: byIdsWo, allIds: allIdsWo} = useSelector(
		(state: Types.RootState) => state.workOrder,
	);
	const {user: authUser} = useSelector(
		(state: Types.RootState) => state.summary,
	);
	const {
		byModule: permissions,
		allSites,
		allIds: userPermissionAllIds,
		byIds: userPermissionByIds,
	} = useSelector((state: Types.RootState) => state.userPermission);
	const {
		allIds: commentAllIds,
		byIds: commentByIds,
		dataUpdated: commentDataUpdated,
	} = useSelector((state: Types.RootState) => state.comment);
	const {dataUpdated, loading, byIds} = useSelector(
		(state: Types.RootState) => state.materialQuery,
	);
	const {
		byIds: masterByIds,
		allIds: masterAllIds,
	} = useSelector((state: Types.RootState) => state.materialMaster);
	const {vendorOptions} = useSelector(
		(state: Types.RootState) => state.summary,
	);

	const [cancelReason, setCancelReason] = useState<string>('');
	const [receiveOptionDescription, setReceiveOptionDescription]
    = useState<string>('');
	const [orderAmount, setOrderAmount] = useState<number>(0);
	const [purchaseOrders, setPurchaseOrders] = useState<MaterialPurchaseOrder[]>([]);
	const [modelType, setModelType] = useState<string>('');
	const [value, setValue] = useState<string>('');
	const [debitWorkOrderId, setDebitWorkOrderId] = useState<number>(-1);
	const [showEscalateModal, setShowEscalateModal] = useState<boolean>(false);
	const [escalatePoint, setEscalatePoint] = useState<string>('');
	const [receivedOption, setReceivedOption] = useState<string>('');
	const [materialLength, setMaterialLength] = useState<string>('');
	const [materialBreadth, setMaterialBreadth] = useState<string>('');
	const [materialHeight, setMaterialHeight] = useState<string>('');
	const [checkedDeliveredQuantity, setCheckedDeliveredQuantity]
    = useState<string>('');
	const [receivedDefectiveItems, setReceivedDefectiveItems]
    = useState<string>('');
	const [recieveAttachmentsFileList, setRecieveAttachmentsFileList] = useState<
	UploadFile[]
	>([]);
	const [
		receivedDefectiveItemsAttachmentsFileList,
		setReceivedDefectiveItemsAttachmentsFileList,
	] = useState<UploadFile[]>([]);
	const [deliveryChallanFileList, setDeliveryChallanFileList] = useState<
	UploadFile[]
	>([]);
	const [materialPhotosFileList, setMaterialPhotosFileList] = useState<
	UploadFile[]
	>([]);
	const [materialLengthFileList, setMaterialLengthFileList] = useState<
	UploadFile[]
	>([]);
	const [materialBreadthFileList, setMaterialBreadthFileList] = useState<
	UploadFile[]
	>([]);
	const [materialHeightFileList, setMaterialHeightFileList] = useState<
	UploadFile[]
	>([]);
	const [filePrefixKeys, setFilePrefixKeys] = useState({});
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

	const before5Days: Date = new Date(
		new Date().setDate(
			new Date().getDate() - materialQueryEscalateAfterDays,
		),
	);
	const orderSites = getPermissionSites(
		permissions,
		ModuleName.MATERIAL_QUERY_ORDER,
		ModulePermission.WRITE,
		allSites,
	);
	const recieveSites = getPermissionSites(
		permissions,
		ModuleName.MATERIAL_QUERY_RECIEVE,
		ModulePermission.WRITE,
		allSites,
	);
	const viewPurchaseOrderSites = getPermissionSites(
		permissions,
		ModuleName.MATERIAL_PURCHASE_ORDER,
		ModulePermission.READ,
		allSites,
	);

	const materialMasters = masterAllIds?.map(id => masterByIds[id]);

	React.useEffect(() => {
		dispatch(getById(queryId));
		dispatch(
			getComments({
				where: {
					or: [
						{queryId},
						{parentId: queryId, parentType: CommentParentType.MATERIAL_QUERY},
					],
				},
			}),
		);

		dispatch(
			getWorkOrders({
				where: {siteId: {inq: orderSites.map((site: Site) => site.id)}},
			}),
		);

		dispatch(getMaterialMasters({}));

		dispatch(getVendors());
	}, []);

	useEffect(() => {
		if (dataUpdated) {
			void message.success('Data updated successfully');
			setTimeout(() => {
				window.location.reload();
			}, 1000);
		}
	}, [dataUpdated]);

	useEffect(() => {
		if (commentDataUpdated) {
			dispatch(
				getComments({
					where: {
						or: [
							{queryId},
							{
								parentId: queryId,
								parentType: CommentParentType.MATERIAL_QUERY,
							},
						],
					},
				}),
			);
		}
	}, [commentDataUpdated]);

	const onDebitQueryClicked = (e: any) => {
		setModelType(modelTypeOptions.debit);
	};

	const onApprovedClicked = (e: any) => {
		setModelType(modelTypeOptions.approve);
	};

	const onOrderedClicked = (e: any) => {
		setModelType(modelTypeOptions.order);
	};

	const onReceiveClicked = (e: any) => {
		console.log(modelTypeOptions.receive);
		setModelType(modelTypeOptions.receive);
	};

	const onBuyLocallyClicked = (e: any) => {
		setModelType(modelTypeOptions.locally);
	};

	const onCanceledClicked = (e: any) => {
		setModelType(modelTypeOptions.cancel);
	};

	const onApproveChangeClicked = (e: any) => {
		setModelType(modelTypeOptions.approveChanges);
	};

	const onCancelChangesClicked = (e: any) => {
		setModelType(modelTypeOptions.cancelChanges);
	};

	const onEscalateQueryClick = () => {
		setShowEscalateModal(true);
	};

	const handleEscalateQueryOk = (escalatedTo?: number) => {
		if (!escalatedTo) {
			return;
		}

		if (!escalatePoint) {
			void message.error('Please enter an escalate point.');
			return;
		}

		setEscalatePoint('');
		setShowEscalateModal(false);
		dispatch(
			escalate(queryId, {
				escalatedTo,
				escalatePoint,
			}),
		);
	};

	const handleEscalateQueryCancel = () => {
		setEscalatePoint('');
		setShowEscalateModal(false);
	};

	const onRequestInstallationClicked = (e: any) => {
		setModelType(modelTypeOptions.requestInstallation);
	};

	const onOrderInstallationClicked = (e: any) => {
		setModelType(modelTypeOptions.orderInstallation);
	};

	const onCompleteInstallationClicked = (e: any) => {
		setModelType(modelTypeOptions.completeInstallation);
	};

	// eslint-disable-next-line complexity
	const handleModelSubmit = e => {
		if (isSubmitting) {
			void message.error('Request is already being processed. Please wait.');
			return;
		}

		setIsSubmitting(true);

		try {
			const query = byIds[queryId];
			if (modelType === modelTypeOptions.cancel) {
				if (!value && !cancelReason) {
					void message.error('Enter cancellation reason');
					setIsSubmitting(false);
					return;
				}

				if (value !== '4') {
					setCancelReason(value);
				}

				dispatch(cancel(queryId, {cancelReason}));
			} else if (modelType === modelTypeOptions.cancelChanges) {
				if (!cancelReason) {
					void message.error('Enter cancellation reason');
					setIsSubmitting(false);
					return;
				}

				dispatch(cancelChanges(queryId, {cancelReason}));
			} else if (modelType === modelTypeOptions.approve) {
				dispatch(approve(queryId, {status: MaterialQueryStatus.APPROVED}));
			} else if (modelType === modelTypeOptions.approveChanges) {
				dispatch(approveChanges(queryId, {}));
			} else if (modelType === modelTypeOptions.order) {
				if (query.debitedToContractor && !(query.orderAmount ?? orderAmount)) {
					void message.error('Enter amount to be debited');
					setIsSubmitting(false);
					return;
				}

				let hasErrors = false;

				purchaseOrders.forEach((po, index) => {
					if (!po.itemId) {
						hasErrors = true;
						void message.error(`Item is required at index ${index}`);
						return;
					}

					if (!po.vendorId) {
						hasErrors = true;
						void message.error(`Vendor is required at index ${index}`);
						return;
					}

					if (!po.quantity || po.quantity <= 0) {
						hasErrors = true;
						void message.error(`Quantity must be greater than 0 at index ${index}`);
						return;
					}

					if (!po.basePrice || po.basePrice <= 0) {
						hasErrors = true;
						void message.error(`Base price must be greater than 0 at index ${index}`);
						return;
					}

					if (!po.gstPercent || po.gstPercent < 0) {
						hasErrors = true;
						void message.error(`GST percentage must be 0 or greater at index ${index}`);
					}
				});
				if (hasErrors) {
					setIsSubmitting(false);
					return;
				}

				const debitNow = query.debitedToContractor && Boolean(query.debitWorkOrderId) && Boolean(orderAmount);
				const markOrderData = {
					orderAmount,
					debitNow,
					debitApproved: debitNow,
					status: MaterialQueryStatus.ORDERED,
					purchaseOrders,
				};
				dispatch(
					update(queryId, markOrderData),
				);
			} else if (modelType === modelTypeOptions.locally) {
				if (query.debitedToContractor && !(query.orderAmount ?? orderAmount)) {
					void message.error('Enter order amount');
					setIsSubmitting(false);
					return;
				}

				const debitNow = query.debitedToContractor && Boolean(query.debitWorkOrderId) && Boolean(orderAmount);
				dispatch(
					update(queryId, {
						orderAmount,
						debitNow,
						debitApproved: debitNow,
						status: MaterialQueryStatus.BUY_LOCALLY,
					}),
				);
			} else if (modelType === modelTypeOptions.receive) {
				if (
					!receivedOption
					|| !checkedDeliveredQuantity
					|| !receivedDefectiveItems
				) {
					void message.error('Please select an option');
					setIsSubmitting(false);
					return;
				}

				if (!receiveOptionDescription) {
					void message.error('Please enter Description of material');
					setIsSubmitting(false);
					return;
				}

				if (query.subCategory?.isMeasurable && !materialLength) {
					void message.error('Please enter Material Length');
					setIsSubmitting(false);
					return;
				}

				if (query.subCategory?.isMeasurable && !materialBreadth) {
					void message.error('Please enter Material Breadth');
					setIsSubmitting(false);
					return;
				}

				if (query.subCategory?.isMeasurable && !materialHeight) {
					void message.error('Please enter Material Height');
					setIsSubmitting(false);
					return;
				}

				if (!receivedDefectiveItemsAttachmentsFileList) {
					void message.error('Please upload attachment');
					setIsSubmitting(false);
					return;
				}

				if (!deliveryChallanFileList?.length) {
					void message.error('Please upload delivery challan photo');
					setIsSubmitting(false);
					return;
				}

				if (!materialPhotosFileList?.length) {
					void message.error('Please upload material photos');
					setIsSubmitting(false);
					return;
				}

				if (query.subCategory?.isMeasurable && !materialLengthFileList?.length) {
					void message.error('Please upload material length photos');
					setIsSubmitting(false);
					return;
				}

				if (query.subCategory?.isMeasurable && !materialBreadthFileList?.length) {
					void message.error('Please upload material breadth photos');
					setIsSubmitting(false);
					return;
				}

				if (query.subCategory?.isMeasurable && !materialHeightFileList?.length) {
					void message.error('Please upload material height photos');
					setIsSubmitting(false);
					return;
				}

				if (query.subCategory?.isMeasurable) {
					checkFileErr(materialLengthFileList);
					checkFileErr(materialBreadthFileList);
					checkFileErr(materialHeightFileList);
				}

				if (recieveAttachmentsFileList) {
					checkFileErr(recieveAttachmentsFileList);
				}

				checkFileErr(receivedDefectiveItemsAttachmentsFileList);
				checkFileErr(deliveryChallanFileList);
				checkFileErr(materialPhotosFileList);

				const recieveAttachments = recieveAttachmentsFileList.map(
					(file: UploadFile) => ({
						name: file.name,
						key: `${filePrefixKeys[file.uid]}/${file.name}`,
						parentType: AttachmentParentType.MATERIAL_QUERY_RECEIVED_OPTION,
					}),
				);
				const receivedDefectiveItemsAttachments
					= receivedDefectiveItemsAttachmentsFileList.map((file: UploadFile) => ({name: file.name, key: `${filePrefixKeys[file.uid]}/${file.name}`, parentType: AttachmentParentType.MATERIAL_QUERY_RECEIVED_DEFECTIVE_ITEM}));
				const deliveryChallanAttachments = deliveryChallanFileList.map(
					(file: UploadFile) => ({
						name: file.name,
						key: `${filePrefixKeys[file.uid]}/${file.name}`,
						parentType: AttachmentParentType.MATERIAL_QUERY_DELIVERY_CHALLAN,
					}),
				);
				const materialPhotosAttachments = materialPhotosFileList.map(
					(file: UploadFile) => ({
						name: file.name,
						key: `${filePrefixKeys[file.uid]}/${file.name}`,
						parentType: AttachmentParentType.MATERIAL_QUERY_MATERIAL_PHOTOS,
					}),
				);
				const materialLenAttachments = materialLengthFileList.map(
					(file: UploadFile) => ({
						name: file.name,
						key: `${filePrefixKeys[file.uid]}/${file.name}`,
						parentType: AttachmentParentType.MATERIAL_QUERY_MATERIAL_LENGTH,
					}),
				);
				const materialBreadthAttachments = materialBreadthFileList.map(
					(file: UploadFile) => ({
						name: file.name,
						key: `${filePrefixKeys[file.uid]}/${file.name}`,
						parentType: AttachmentParentType.MATERIAL_QUERY_MATERIAL_BREADTH,
					}),
				);
				const materialHeightAttachments = materialHeightFileList.map(
					(file: UploadFile) => ({
						name: file.name,
						key: `${filePrefixKeys[file.uid]}/${file.name}`,
						parentType: AttachmentParentType.MATERIAL_QUERY_MATERIAL_HEIGHT,
					}),
				);
				const approvalData = JSON.stringify({
					status: receivedOption,
					recieveAttachments,
					receivedOptionDescription: receiveOptionDescription,
					checkedDeliveredQuantity,
					materialLength,
					materialBreadth,
					materialHeight,
					deliveryChallanAttachments,
					materialPhotosAttachments,
					receivedDefectiveItems,
					receivedDefectiveItemsAttachments,
					materialLengthAttachments: materialLenAttachments,
					materialBreadthAttachments,
					materialHeightAttachments,
				});
				setReceivedOption('');
				setCheckedDeliveredQuantity('');
				setReceivedDefectiveItems('');
				setRecieveAttachmentsFileList([]);
				setDeliveryChallanFileList([]);
				setReceivedDefectiveItemsAttachmentsFileList([]);
				setMaterialPhotosFileList([]);
				setFilePrefixKeys({});
				setReceiveOptionDescription('');
				setMaterialLength('');
				setMaterialBreadth('');
				setMaterialHeight('');
				setMaterialLengthFileList([]);
				setMaterialBreadthFileList([]);
				setMaterialHeightFileList([]);

				dispatch(
					receive(queryId, {
						approvalData,
					}),
				);
			} else if (modelType === modelTypeOptions.debit) {
				if (!orderAmount) {
					void message.error('Enter order amount');
					setIsSubmitting(false);
					return;
				}

				if (!debitWorkOrderId) {
					void message.error('Please select a work order to debit from!');
					setIsSubmitting(false);
					return;
				}

				dispatch(
					update(queryId, {
						debitedToContractor: true,
						orderAmount,
						debitWorkOrderId,
						debitNow: true,
						debitApproved: true,
					}),
				);
			} else if (modelType === modelTypeOptions.requestInstallation) {
				dispatch(update(queryId, {
					status: MaterialQueryStatus.INSTALLATION_REQUESTED,
				}));
			} else if (modelType === modelTypeOptions.orderInstallation) {
				dispatch(update(queryId, {
					status: MaterialQueryStatus.INSTALLATION_ORDERED,
				}));
			} else if (modelType === modelTypeOptions.completeInstallation) {
				dispatch(update(queryId, {
					status: MaterialQueryStatus.INSTALLATION_COMPLETED,
					installationCompleted: true,
				}));
			}

			setModelType('');
		} catch (error) {
			console.error('Error in handleModelSubmit:', error);
			setIsSubmitting(false);
		}
	};

	const query = byIds[queryId];
	const comments = commentAllIds?.map(id => commentByIds[id]);

	if (!query) {
		return (
			<DefaultLayout>
				<Row>
					<Col span={24} style={{textAlign: 'center'}}>
						<Empty />
					</Col>
				</Row>
			</DefaultLayout>
		);
	}

	const orderOptions: FilterOptionItem[] = allIdsWo
		.filter(
			(id: number) =>
				(!query.siteId || byIdsWo[id].siteId === query.siteId)
        && byIdsWo[id].status === WorkOrderStatus.PUBLISHED,
		)
		.map((id: number) => ({value: id.toString(), label: byIdsWo[id].title}));
	const userPermission = userPermissionAllIds
		.map(id => userPermissionByIds[id])
		.find(userPermission => userPermission.siteId === query.siteId);
	const escalatedToUser = userPermission?.user;

	const canApprove = equalNum(query?.nextApprovedBy, authUser?.id);
	const canOrder = orderSites.find(({id}) => equalNum(id, query?.siteId)) ?? canApprove;
	const canViewPurchaseOrder = viewPurchaseOrderSites.find(({id}) => equalNum(id, query?.siteId));
	const canRecieve
    = (query?.status === MaterialQueryStatus.ORDERED
      || query?.status === MaterialQueryStatus.BUY_LOCALLY
      || query?.status === MaterialQueryStatus.RECEIVED_A
      || query?.status === MaterialQueryStatus.RECEIVED_C
      || query?.status === MaterialQueryStatus.RECEIVED_D)
    && recieveSites.find(s => equalNum(s.id, query?.siteId));

	const canRequestInstallation
		= query?.status === MaterialQueryStatus.RECEIVED_B
		&& query?.subCategory?.requiresInstallation
		&& !canOrder;

	const canOrderInstallation
		= query?.status === MaterialQueryStatus.INSTALLATION_REQUESTED
		&& canOrder;

	const canCompleteInstallation
		= query?.status === MaterialQueryStatus.INSTALLATION_ORDERED
		&& recieveSites.find(s => equalNum(s.id, query?.siteId));

	let statusColor = '';
	if (query.status === MaterialQueryStatus.APPROVED) {
		statusColor = 'cyan';
	} else if (query.status === MaterialQueryStatus.ORDERED) {
		statusColor = 'green';
	} else if (query.status === MaterialQueryStatus.BUY_LOCALLY) {
		statusColor = 'blue';
	} else if (query.status === MaterialQueryStatus.CANCELED) {
		statusColor = 'red';
	} else if (query.status === MaterialQueryStatus.INSTALLATION_REQUESTED) {
		statusColor = 'orange';
	} else if (query.status === MaterialQueryStatus.INSTALLATION_ORDERED) {
		statusColor = 'purple';
	} else if (query.status === MaterialQueryStatus.INSTALLATION_COMPLETED) {
		statusColor = 'gold';
	} else {
		statusColor = 'black';
	}

	return (
		<DefaultLayout currentPath={uiPaths.materialQueryDetail}>
			<Spin size='large' spinning={loading} tip={'Loading...'}>
				<Badge.Ribbon
					style={{height: 25}}
					color={statusColor}
					text={query.status ? materialQueryStatusLabels[query.status] : ''}
				>
					<Card size='small'>
						<Row>
							<Col span={24}>
								<br />
								<Button
									onClick={e => {
										history.goBack();
									}}
								>
                  Back
								</Button>
								<h3 style={{textAlign: 'center'}}>{query.site?.name}</h3>
								<br />
								<br />
								<Space direction='vertical'>
									<div>
										<b>Id</b> : {query.id}
									</div>
									{query.category ? (
										<div>
											<b>Category</b> : {query.category.name}
										</div>
									) : (
										[]
									)}
									{query.subCategory ? (
										<div>
											<b>Sub Category</b> : {query.subCategory.name}
										</div>
									) : (
										[]
									)}
									{query.status === MaterialQueryStatus.CANCELED && query.cancelReason ? (
										<div>
											<b>Cancel Reason</b> : {query.cancelReason}
										</div>
									) : []}
									{query.purchaseOrders?.length && (canOrder ?? canViewPurchaseOrder) ? (
										<PurchaseOrderView purchaseOrders={query.purchaseOrders} />
									) : []}
								</Space>
								<br />
								<br />
								<CommentComponent
									author={
										<span
											style={{
												color: '#222222',
												fontWeight: 'bold',
												fontSize: '14px',
											}}
										>
											{query.createdUser ? query.createdUser.name : 'User'}
										</span>
									}
									avatar={<Avatar icon={<UserOutlined />} />}
									content={
										<Row>
											<Col>
												<Space direction={'vertical'}>
													<p>{query.description}</p>
													{query.attachments?.length
														? query.attachments.map(
															(attachment, ix: number) => (
																<a
																	key={ix}
																	href={getS3Url(attachment.key ?? '')}
																	target='_blank'
																	rel='noreferrer'
																>
																	<PaperClipOutlined /> {attachment.name}
																</a>
															),
														)
														: []}
													{query.debitedToContractor ? (
														<Descriptions
															bordered={true}
															column={1}
															size={'small'}
														>
															<Descriptions.Item label='Debited to Contractor'>
																{query.debitedToContractor ? 'Yes' : 'No'}
															</Descriptions.Item>
															<Descriptions.Item label='Amount to be Debited to Contractor'>
																{query.orderAmount
                                  ?? `To be Entered by ${toTitleCase(UserRole.PROCUREMENT_INCHARGE)}`}
															</Descriptions.Item>
															<Descriptions.Item label='Debited Work Order'>
																{query.debitWorkOrder ? (
																	<a
																		target='_blank'
																		href={getHrefLink(
																			uiPaths.workOrderDetail,
																			query.debitWorkOrder.id,
																		)}
																		rel='noreferrer'
																	>
																		{query.debitWorkOrder.title}
																	</a>
																) : (
																	[]
																)}
															</Descriptions.Item>
															<Descriptions.Item label='Debit Approve'>
																{query.debitApproved
																	? 'Yes'
																	: `No, To be Approve by ${toTitleCase(
																		UserRole.CONTRACT_DEVELOPMENT_AND_RELATIONSHIP_MANAGER,
																	)}`}
															</Descriptions.Item>
														</Descriptions>
													) : (
														[]
													)}
												</Space>
											</Col>
										</Row>
									}
									datetime={
										<span style={{color: '#444444', fontSize: '14px'}}>
											{moment(query.createdAt).fromNow()}
										</span>
									}
								/>
							</Col>
						</Row>
						<CommentBlock
							parentId={queryId}
							parentType={CommentParentType.MATERIAL_QUERY}
							authUser={authUser}
							comments={comments}
							canComment={true}
							saveComment={data => dispatch(saveComment(data))}
						/>
						{query.status === MaterialQueryStatus.RECEIVED_A
            || query.status === MaterialQueryStatus.RECEIVED_B
            || query.status === MaterialQueryStatus.RECEIVED_C
            || query.status === MaterialQueryStatus.RECEIVED_D ? (
								<Card size='small' title='"Mark as Received " Responses'>
									<Row>
										<Col span={24}>
											<Space direction='vertical'>
												{query.status ? (
													<>
														<p>1. Received Option</p>
														<p>{materialQueryStatusLabels[query.status]}</p>
														<p>
															{query.receivedOptionDescription
                              ?? query.receivedOptionDescription}
														</p>
														{query.recieveAttachments?.length
															? query.recieveAttachments.map(
																(attachment: Attachment, ix: number) => (
																	<Col span={24} key={ix}>
																		<PaperClipOutlined />{' '}
																		<a
																			href={getS3Url(attachment.key ?? '')}
																			target='_blank'
																			rel='noreferrer'
																		>
																			{attachment.name}
																		</a>
																	</Col>
																),
															)
															: []}
													</>
												) : (
													[]
												)}

												{query.checkedDeliveredQuantity ? (
													<>
														<p>
                            2. Who has checked that the delivered quantity is
                            matching with as mentioned in the delivery challan?
														</p>
														<p>{query.checkedDeliveredQuantity}</p>
														<p>Delivery Challan Attachments</p>
														{query.deliveryChallanAttachments?.length
															? query.deliveryChallanAttachments.map(
																(attachment: Attachment, ix: number) => (
																	<Col span={24} key={ix}>
																		<PaperClipOutlined />{' '}
																		<a
																			href={getS3Url(attachment.key ?? '')}
																			target='_blank'
																			rel='noreferrer'
																		>
																			{attachment.name}
																		</a>
																	</Col>
																),
															)
															: []}
														<p>Material Photos Attachments</p>
														{query.materialPhotosAttachments?.length
															? query.materialPhotosAttachments.map(
																(attachment: Attachment, ix: number) => (
																	<Col span={24} key={ix}>
																		<PaperClipOutlined />{' '}
																		<a
																			href={getS3Url(attachment.key ?? '')}
																			target='_blank'
																			rel='noreferrer'
																		>
																			{attachment.name}
																		</a>
																	</Col>
																),
															)
															: []}
													</>
												) : (
													[]
												)}
												{query.receivedDefectiveItems ? (
													<>
														<p>
                            3. Have you received any broken material or
                            defective items in material delivered?
														</p>
														<p>{query.receivedDefectiveItems}</p>
														{query.receivedDefectiveItemsAttachments?.length
															? query.receivedDefectiveItemsAttachments.map(
																(attachment: Attachment, ix: number) => (
																	<Col span={24} key={ix}>
																		<PaperClipOutlined />{' '}
																		<a
																			href={getS3Url(attachment.key ?? '')}
																			target='_blank'
																			rel='noreferrer'
																		>
																			{attachment.name}
																		</a>
																	</Col>
																),
															)
															: []}
													</>
												) : (
													[]
												)}
												{query.materialLength ? (
													<>
														<p>
                            4. Have you measured and noted down the length of
                            the delivered material?
														</p>
														<p>{query.materialLength}</p>
														{query.materialLengthAttachments?.length
															? query.materialLengthAttachments.map(
																(attachment: Attachment, ix: number) => (
																	<Col span={24} key={ix}>
																		<PaperClipOutlined />{' '}
																		<a
																			href={getS3Url(attachment.key ?? '')}
																			target='_blank'
																			rel='noreferrer'
																		>
																			{attachment.name}
																		</a>
																	</Col>
																),
															)
															: []}
													</>
												) : (
													[]
												)}

												{query.materialBreadth ? (
													<>
														<p>
                            5. Have you measured and noted down the breadth of
                            the delivered material?
														</p>
														<p>{query.materialBreadth}</p>
														{query.materialBreadthAttachments?.length
															? query.materialBreadthAttachments.map(
																(attachment: Attachment, ix: number) => (
																	<Col span={24} key={ix}>
																		<PaperClipOutlined />{' '}
																		<a
																			href={getS3Url(attachment.key ?? '')}
																			target='_blank'
																			rel='noreferrer'
																		>
																			{attachment.name}
																		</a>
																	</Col>
																),
															)
															: []}
													</>
												) : (
													[]
												)}

												{query.materialHeight ? (
													<>
														<p>
                            6. Have you measured and noted down the Height of
                            the delivered material?
														</p>
														<p>{query.materialHeight}</p>
														{query.materialHeightAttachments?.length
															? query.materialHeightAttachments.map(
																(attachment: Attachment, ix: number) => (
																	<Col span={24} key={ix}>
																		<PaperClipOutlined />{' '}
																		<a
																			href={getS3Url(attachment.key ?? '')}
																			target='_blank'
																			rel='noreferrer'
																		>
																			{attachment.name}
																		</a>
																	</Col>
																),
															)
															: []}
													</>
												) : (
													[]
												)}
											</Space>
										</Col>
									</Row>
								</Card>
							) : (
								[]
							)}
						<Row>
							<Col span={24} className='mt-15' style={{textAlign: 'right'}}>
								<Form>
									<Form.Item>
										<Space wrap={true}>
											{canApprove && query.nextApprovedUser ? (
												query.status === MaterialQueryStatus.PENDING ? (
													<>
														<Button type='primary' onClick={onApprovedClicked}>
                              Approve
														</Button>
														<Button
															type='primary'
															danger={true}
															onClick={onCanceledClicked}
														>
                              Cancel
														</Button>
													</>
												) : (
													<>
														<Button
															type='primary'
															onClick={onApproveChangeClicked}
														>
                              Approve
														</Button>
														<Button
															type='primary'
															danger={true}
															onClick={onCancelChangesClicked}
														>
                              Cancel
														</Button>
													</>
												)
											) : (
												[]
											)}
											{query.nextApprovedUser
                      && query.status === MaterialQueryStatus.PENDING ? (
													<h4>
                          Pending for Approval from{' '}
														{query.nextApprovedUser?.name}
													</h4>
												) : query.createdBy === authUser?.id
                        && query.nextApprovedUser ? (
														<h4>
                          Pending for Approval from{' '}
															{query.nextApprovedUser?.name}
														</h4>
													) : (
														[]
													)}
											{canOrder ? (
												<>
													{query.status === MaterialQueryStatus.APPROVED ? (
														<>
															<SafeSubmitButton type='primary' onClick={onOrderedClicked}>
                                Mark this Query as Ordered
															</SafeSubmitButton>
															<Button
																type='primary'
																danger={true}
																onClick={onCanceledClicked}
															>
                                Cancel
															</Button>
															<Button
																type='primary'
																onClick={onBuyLocallyClicked}
															>
                                Buy Locally
															</Button>
														</>
													) : (
														[]
													)}
													{/* {!query.debitedToContractor
                          && query.status !== MaterialQueryStatus.CANCELED ? (
															<>
																<SafeSubmitButton
																	type='primary'
																	onClick={onDebitQueryClicked}
																>
                                Debit this Query to a Contractor
																</SafeSubmitButton>
															</>
														) : (
															[]
														)} */}
													{query.status === MaterialQueryStatus.ORDERED
                          || query.status === MaterialQueryStatus.BUY_LOCALLY ? (
															<Button
																type='primary'
																danger={true}
																onClick={onCanceledClicked}
															>
                              Cancel
															</Button>
														) : (
															[]
														)}
												</>
											) : (
												<>
													{query.status === MaterialQueryStatus.APPROVED ? (
														<h4>Pending for Order</h4>
													) : (
														[]
													)}
												</>
											)}

											{(canApprove || canOrder) && !query.debitedToContractor && query.status !== MaterialQueryStatus.CANCELED ? (
												<SafeSubmitButton
													type='primary'
													onClick={onDebitQueryClicked}
												>
													Debit this Query to a Contractor
												</SafeSubmitButton>
											) : (
												[]
											)}
											{canRecieve
												&& query.status !== MaterialQueryStatus.RECEIVED_B
												&& !query.nextApprovedBy ? (
													<SafeSubmitButton type='primary' onClick={onReceiveClicked}>
														Mark this Query as Received
													</SafeSubmitButton>
												) : (
													[]
												)}
											{canRequestInstallation ? (
												<SafeSubmitButton type='primary' onClick={onRequestInstallationClicked}>
													Request Installation
												</SafeSubmitButton>
											) : (
												[]
											)}
											{canOrderInstallation ? (
												<SafeSubmitButton type='primary' onClick={onOrderInstallationClicked}>
													Order Installation
												</SafeSubmitButton>
											) : (
												[]
											)}
											{canCompleteInstallation ? (
												<SafeSubmitButton type='primary' onClick={onCompleteInstallationClicked}>
													Complete Installation
												</SafeSubmitButton>
											) : (
												[]
											)}
										</Space>
									</Form.Item>
								</Form>
							</Col>
						</Row>
						{query.createdBy === authUser?.id ? (
							<Row>
								<Col span={24} className='mt-15'>
									<Card size='small' title={'Escalate this Query'}>
										{'Escalate to the procurement head if,'} <br />
										{`- you don't get any reply or not ordered within 5 days of
										raising the request.`}
										<br />
										{'- you are not satisfied with the order or response.'}
										<br />
										<br />
										{/* Escalation feature temporarily disabled
										{escalatedToUser?.id
                    && query.status !== MaterialQueryStatus.RECEIVED_B
                    && query.status !== MaterialQueryStatus.CANCELED
                    && (!query.createdAt
                      || new Date(String(query.createdAt)) < before5Days) ? (
												<Button
													type={'primary'}
													size={'small'}
													onClick={onEscalateQueryClick}
												>
                        Escalate this Query
												</Button>
											) : (
												<b>Query can not be escalated now.</b>
											)}{' '}
										{query.escalatedUser ? (
											<span>
                        Query last escalated to {query.escalatedUser?.name} (
												{toTitleCase(query.escalatedUser?.roles)}) at{' '}
												{formatDateFunction(query.escalatedAt, true)}.
											</span>
										) : (
											[]
										)}
										*/}
										<b>Escalation feature is temporarily disabled.</b>
										<br />
										<br />
									</Card>
								</Col>
							</Row>
						) : (
							[]
						)}
					</Card>
				</Badge.Ribbon>

				<Modal
					title={modelType}
					width={1200}
					open={Boolean(modelType)}
					onOk={handleModelSubmit}
					onCancel={e => {
						setModelType('');
						setDebitWorkOrderId(0);
						setCancelReason('');
						setOrderAmount(0);
					}}
				>
					<Form name='basic'>
						{modelType === modelTypeOptions.approve
            || modelType === modelTypeOptions.approveChanges ? (
								<>
									<p>Approve this Query?</p>
									{query.debitedToContractor && (
										<>
											<Form.Item label='Amount to be Debited to Contractor'>
												<Input
													type={'number'}
													onChange={(e: any) => {
														setOrderAmount(parseInt(String(e.target.value), 10));
													}}
												/>
											</Form.Item>
											<Form.Item label='Work Order of Contractor'>
												<Select
													showSearch={true}
													style={{width: 250}}
													placeholder='Select a Work Order'
													optionFilterProp='children'
													onChange={value => {
														setDebitWorkOrderId(parseNum(value as string));
													}}
												>
													{orderOptions.map(
														(option: FilterOptionItem, ix: number) => (
															<Select.Option key={ix} value={option.value}>
																{option.label}
															</Select.Option>
														),
													)}
												</Select>
											</Form.Item>
										</>
									)}
								</>
							) : (
								[]
							)}

						{modelType === modelTypeOptions.cancelChanges ? (
							<>
								<Space direction='vertical' style={{width: '100%'}}>
									<p>Specify reason for cancelling changes</p>
									<Input.TextArea
										style={{width: '100%'}}
										rows={3}
										placeholder={'Enter Cancellation Reason'}
										defaultValue={cancelReason ?? ''}
										value={cancelReason ?? ''}
										onChange={(e: any) => {
											setCancelReason(String(e.target.value));
										}}
									/>
								</Space>
							</>
						) : (
							[]
						)}

						{modelType === modelTypeOptions.cancel ? (
							<Radio.Group
								onChange={(e: any) => {
									setValue(String(e.target.value));
								}}
								value={value}
							>
								<Space direction='vertical'>
									<Radio value={'Wrong Order Details'}>
                    Wrong Order Details
									</Radio>
									<Radio
										value={
											'Order is placed very much prior(>15 days) than it\'s actual requirement '
										}
									>
										{`Order is placed very much prior(>15 days) than it's
										actual requirement`}
									</Radio>
									<Radio value={'Incomplete Order Details'}>
                    Incomplete Order Details
									</Radio>
									<Radio value={'4'}>
                    Any other reason, please specify
										{value === '4' ? (
											<Input.TextArea
												rows={3}
												placeholder={'Enter Cancellation Reason'}
												defaultValue={cancelReason ?? ''}
												value={cancelReason ?? ''}
												onChange={(e: any) => {
													setCancelReason(String(e.target.value));
												}}
											/>
										) : null}
									</Radio>
								</Space>
							</Radio.Group>
						) : (
							[]
						)}

						{(modelType === modelTypeOptions.order
              || modelType === modelTypeOptions.locally)
            && query.debitedToContractor
            && !query.orderAmount ? (
								<Form.Item label='Amount to be Debited to Contractor'>
									<Input
										type={'number'}
										onChange={(e: any) => {
											setOrderAmount(parseNum(String(e.target.value)));
										}}
									/>
								</Form.Item>
							) : (
								[]
							)}

						{modelType === modelTypeOptions.order ? (
							<Space direction='vertical'>
								<PurchaseOrderForm
									materialQueryId={query.id}
									siteId={query.siteId}
									materialMasters={materialMasters}
									vendors={vendorOptions}
									onChange={items => {
										setPurchaseOrders(items);
									}}
								/>
							</Space>
						) : (
							[]
						)}

						{modelType === modelTypeOptions.receive ? (
							<Space direction='vertical'>
								<Select
									value={receivedOption}
									style={{width: '100%'}}
									placeholder='Select an option'
									onChange={value => {
										setReceivedOption(value);
									}}
								>
									<Select.Option value={MaterialQueryStatus.RECEIVED_A}>
										{materialQueryStatusLabels.RECEIVED_A}
									</Select.Option>
									<Select.Option value={MaterialQueryStatus.RECEIVED_B}>
										{materialQueryStatusLabels.RECEIVED_B}
									</Select.Option>
									<Select.Option value={MaterialQueryStatus.RECEIVED_C}>
										{materialQueryStatusLabels.RECEIVED_C}
									</Select.Option>
									<Select.Option value={MaterialQueryStatus.RECEIVED_D}>
										{materialQueryStatusLabels.RECEIVED_D}
									</Select.Option>
								</Select>
								{receivedOption === String(MaterialQueryStatus.RECEIVED_A) ? (
									<Input.TextArea
										rows={3}
										placeholder={
											'Mention the details of the material which was ordered but not received'
										}
										defaultValue={receiveOptionDescription ?? ''}
										value={receiveOptionDescription ?? ''}
										onChange={(e: any) => {
											setReceiveOptionDescription(String(e.target.value));
										}}
									/>
								) : (
									[]
								)}
								{receivedOption === String(MaterialQueryStatus.RECEIVED_B) ? (
									<Input.TextArea
										rows={3}
										placeholder={
											'Mention the details of the material which was ordered and received'
										}
										defaultValue={receiveOptionDescription ?? ''}
										value={receiveOptionDescription ?? ''}
										onChange={(e: any) => {
											setReceiveOptionDescription(String(e.target.value));
										}}
									/>
								) : (
									[]
								)}
								{receivedOption === String(MaterialQueryStatus.RECEIVED_C) ? (
									<>
										<Input.TextArea
											rows={3}
											placeholder={
												'Mention the details of the material which was ordered and the material which site received'
											}
											defaultValue={receiveOptionDescription ?? ''}
											value={receiveOptionDescription ?? ''}
											onChange={(e: any) => {
												setReceiveOptionDescription(String(e.target.value));
											}}
										/>
										<FileUpload
											label={'Click to Upload Attachments'}
											prefix={'material-query'}
											fileList={recieveAttachmentsFileList}
											filePrefixKeys={filePrefixKeys}
											onFileChange={(
												recieveAttachmentsFileList,
												filePrefixKeys,
											) => {
												setRecieveAttachmentsFileList(
													recieveAttachmentsFileList,
												);
												setFilePrefixKeys(filePrefixKeys);
											}}
										/>
									</>
								) : null}
								{receivedOption === String(MaterialQueryStatus.RECEIVED_D) ? (
									<>
										<Input.TextArea
											rows={3}
											placeholder={
												'Mention the details of the material which was ordered and the material which site received'
											}
											defaultValue={receiveOptionDescription ?? ''}
											value={receiveOptionDescription ?? ''}
											onChange={(e: any) => {
												setReceiveOptionDescription(String(e.target.value));
											}}
										/>
										<FileUpload
											label={'Click to Upload Attachments'}
											prefix={'material-query'}
											fileList={recieveAttachmentsFileList}
											filePrefixKeys={filePrefixKeys}
											onFileChange={(
												recieveAttachmentsFileList,
												filePrefixKeys,
											) => {
												setRecieveAttachmentsFileList(
													recieveAttachmentsFileList,
												);
												setFilePrefixKeys(filePrefixKeys);
											}}
										/>
									</>
								) : (
									[]
								)}
								<label>
				Who has checked that the delivered quantity is matching with
				as mentioned in the delivery challan?
								</label>
								<Select
									value={checkedDeliveredQuantity}
									style={{width: '100%'}}
									placeholder='Please select a reply'
									onChange={value => {
										setCheckedDeliveredQuantity(value);
									}}
								>
									<Select.Option value={'APM'}>APM</Select.Option>
									<Select.Option value={'Security Guard'}>
					Security Guard
									</Select.Option>
									<Select.Option value={'Site Incharge'}>
					Site Incharge
									</Select.Option>
									<Select.Option value={'No One'}>No One</Select.Option>
								</Select>
								<FileUpload
									single={true}
									label={'Click to Upload Delivery Challan'}
									prefix={'material-query'}
									fileList={deliveryChallanFileList}
									filePrefixKeys={filePrefixKeys}
									onFileChange={(deliveryChallanFileList, filePrefixKeys) => {
										setDeliveryChallanFileList(deliveryChallanFileList);
										setFilePrefixKeys(filePrefixKeys);
									}}
								/>
								<FileUpload
									label={'Click to Upload Material Photos'}
									prefix={'material-query'}
									fileList={materialPhotosFileList}
									filePrefixKeys={filePrefixKeys}
									onFileChange={(materialPhotosFileList, filePrefixKeys) => {
										setMaterialPhotosFileList(materialPhotosFileList);
										setFilePrefixKeys(filePrefixKeys);
									}}
								/>
								<label>
				Have you received any broken material or defective items in
				material delivered?
								</label>
								<Select
									value={receivedDefectiveItems}
									style={{width: '100%'}}
									placeholder='Please select a reply'
									onChange={value => {
										setReceivedDefectiveItems(value);
									}}
								>
									<Select.Option value={'Yes'}>Yes</Select.Option>
									<Select.Option value={'No'}>No</Select.Option>
								</Select>
								<FileUpload
									label={'Click to Upload Attachments'}
									prefix={'material-query'}
									fileList={receivedDefectiveItemsAttachmentsFileList}
									filePrefixKeys={filePrefixKeys}
									onFileChange={(
										receivedDefectiveItemsAttachmentsFileList,
										filePrefixKeys,
									) => {
										setReceivedDefectiveItemsAttachmentsFileList(
											receivedDefectiveItemsAttachmentsFileList,
										);
										setFilePrefixKeys(filePrefixKeys);
									}}
								/>
								{query.subCategory?.isMeasurable ? (
									<>
										<label>
					Have you measured and noted down the length of the
					delivered material?
										</label>
										<Input
											placeholder={
												'Enter the length of the delivered material'
											}
											value={materialLength}
											onChange={(e: any) => {
												setMaterialLength(String(e.target.value));
											}}
										/>
										<FileUpload
											label={'Upload Photo of the length measurement'}
											prefix={'material-query'}
											fileList={materialLengthFileList}
											filePrefixKeys={filePrefixKeys}
											onFileChange={(
												materialLengthFileList,
												filePrefixKeys,
											) => {
												setMaterialLengthFileList(materialLengthFileList);
												setFilePrefixKeys(filePrefixKeys);
											}}
										/>
										<label>
					Have you measured and noted down the breadth of the
					delivered material?
										</label>
										<Input
											placeholder={
												'Enter the breadth of the delivered material'
											}
											value={materialBreadth}
											onChange={(e: any) => {
												setMaterialBreadth(String(e.target.value));
											}}
										/>
										<FileUpload
											label={'Upload Photo of the breadth measurement'}
											prefix={'material-query'}
											fileList={materialBreadthFileList}
											filePrefixKeys={filePrefixKeys}
											onFileChange={(
												materialBreadthFileList,
												filePrefixKeys,
											) => {
												setMaterialBreadthFileList(materialBreadthFileList);
												setFilePrefixKeys(filePrefixKeys);
											}}
										/>
										<label>
					Have you measured and noted down the height of the
					delivered material?
										</label>
										<Input
											placeholder={
												'Enter the height of the delivered material'
											}
											value={materialHeight}
											onChange={(e: any) => {
												setMaterialHeight(String(e.target.value));
											}}
										/>
										<FileUpload
											label={'Upload Photo of the height measurement'}
											prefix={'material-query'}
											fileList={materialHeightFileList}
											filePrefixKeys={filePrefixKeys}
											onFileChange={(
												materialHeightFileList,
												filePrefixKeys,
											) => {
												setMaterialHeightFileList(materialHeightFileList);
												setFilePrefixKeys(filePrefixKeys);
											}}
										/>
									</>
								) : (
									[]
								)}
							</Space>
						) : (
							[]
						)}

						{modelType === modelTypeOptions.debit ? (
							<>
								<Form.Item label='Amount to be Debited to Contractor'>
									<Input
										type={'number'}
										onChange={(e: any) => {
											setOrderAmount(parseInt(String(e.target.value), 10));
										}}
									/>
								</Form.Item>
								<Form.Item label='Work Order of Contractor'>
									<Select
										showSearch={true}
										style={{width: 250}}
										placeholder='Select a Work Order'
										optionFilterProp='children'
										onChange={value => {
											setDebitWorkOrderId(parseNum(value as string));
										}}
									>
										{orderOptions.map(
											(option: FilterOptionItem, ix: number) => (
												<Select.Option key={ix} value={option.value}>
													{option.label}
												</Select.Option>
											),
										)}
									</Select>
								</Form.Item>
							</>
						) : (
							[]
						)}

						{modelType === modelTypeOptions.requestInstallation ? (
							<Space direction='vertical' style={{width: '100%'}}>
								<p>Request installation for this material</p>
								<p>This will mark the material query as requiring installation.</p>
							</Space>
						) : (
							[]
						)}

						{modelType === modelTypeOptions.orderInstallation ? (
							<Space direction='vertical' style={{width: '100%'}}>
								<p>Order installation for this material</p>
								<p>This will mark the installation as ordered and notify the site team.</p>
							</Space>
						) : (
							[]
						)}

						{modelType === modelTypeOptions.completeInstallation ? (
							<Space direction='vertical' style={{width: '100%'}}>
								<p>Mark installation as completed</p>
								<p>This will mark the installation as completed.</p>
							</Space>
						) : (
							[]
						)}
					</Form>
				</Modal>

				<Modal
					title='Escalate this Query'
					open={showEscalateModal}
					onOk={() => {
						handleEscalateQueryOk(escalatedToUser?.id);
					}}
					onCancel={handleEscalateQueryCancel}
				>
					<Form layout='vertical'>
						<Form.Item>
              Query is being Escalated to {escalatedToUser?.name} (
							{toTitleCase(escalatedToUser?.roles)})
						</Form.Item>
						<Form.Item label='Please Enter an Escalation Point'>
							<Input.TextArea
								placeholder={'Enter Escalation Point'}
								defaultValue={escalatePoint ?? ''}
								value={escalatePoint ?? ''}
								onChange={(e: any) => {
									setEscalatePoint(String(e.target.value));
								}}
							/>
						</Form.Item>
					</Form>
				</Modal>
			</Spin>
		</DefaultLayout>
	);
};
